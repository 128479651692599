.loader {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 8px;
}

.loader div {
  width: 10px;
  height: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #99abd6;
  border-radius: 50%;
}

.loader div:before,
.loader div:after {
  content: '';
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 50%;
}

.loader div:before {
  background-color: rgb(252, 235, 220);
  animation: scale-1 2400ms linear infinite;
}

.loader div:after {
  background-color: #99abd6;
  animation: scale-2 2400ms linear infinite;
}

.loader div:nth-child(2):before,
.loader div:nth-child(2):after {
  animation-delay: 300ms;
}

.loader div:nth-child(3):before,
.loader div:nth-child(3):after {
  animation-delay: 600ms;
}

.loader div:nth-child(4):before,
.loader div:nth-child(4):after {
  animation-delay: 900ms;
}

.loader div:nth-child(5):before,
.loader div:nth-child(5):after {
  animation-delay: 1200ms;
}

.loader div:nth-child(6):before,
.loader div:nth-child(6):after {
  animation-delay: 1500ms;
}

.loader div:nth-child(7):before,
.loader div:nth-child(7):after {
  animation-delay: 1800ms;
}

.loader div:nth-child(8):before,
.loader div:nth-child(8):after {
  animation-delay: 2100ms;
}

.loader div:nth-child(9):before,
.loader div:nth-child(9):after {
  animation-delay: 2400ms;
}

@keyframes scale-1 {
  0% {
    transform: scale(0);
    z-index: 2;
  }
  50%,
  100% {
    transform: scale(1);
  }
}

@keyframes scale-2 {
  0%,
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.fade-in-out {
    animation: fade-in-out 2s ease-in-out infinite;
  }
  
  @keyframes fade-in-out {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

.fade-colors {
    animation: fade-colors 2s ease-in-out infinite;
  }
  
  @keyframes fade-colors {
    0% {
      color: #99abd6;
    }
    50% {
      color: #fcebdc;
    }
    100% {
      color: #99abd6;
    }
  }

  .fade-colors-dark {
    animation: fade-colors-dark 4s ease-in-out infinite;
  }
  
  @keyframes fade-colors-dark {
    0% {
      color: #99abd6;
      background-color: #121212;
    }
    50% {
      color: #121212;
      background-color: #99abd6;
    }
    100% {
      color: #99abd6;
      background-color: #121212;
    }
  }

  .rotate-infinite {
    animation: rotate 2s linear infinite;
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }